import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const Faqs = () => (
  <Layout>
    <div className="faq">
        <h1 className="faq-welcome">Common Questions</h1>
        <div class="faq-main">
            <p className="faq-par1">
                <h2 className="faq-par-header">How do I place an order?</h2>
                You can place an order directly through our online store <a href='https://hambyartisanbakery.com'>here</a>. 
                <br />
                <b>Orders must be placed 48 hours in advance.</b> Before you check out, be sure to select a delivery date from the date field in your cart page, and include any other special requests
                or instructions, in the "Order special instructions" box in your cart. We will reach out to you at the email address you provided for check out 
                if we have any further questions.
            </p>
            <p className="faq-par2">
                <h2 className="faq-par-header">What does from scratch mean?</h2>
                We make everything in small batches (no manufacturing of 1000 cookies at once!!) -- which means we use real ingredients, without preservatives, artificial colors, flavors, or seed oils. 
                You won't find any mixes, and we do not cut corners in our kitchen! We are committed to delivering the highest quality treats, so we use certified organic ingredients as much as possible. 
                If an item contains a non-organic ingredient, it will be listed in the description of the specific item.
            </p>
            <p className="faq-par3">
                <h2 className="faq-par-header">Can I request baked goods not listed online?</h2>
                You are always more than welcome to reach out to us with special requests by contacting us <a href="https://hamby-artisan-bakery.myshopify.com/pages/contact">here</a>. 
                While we cannot guarantee that we will be able to fulfill your request, we will do our best. 
                If it is something we can't do now, we will let you know and and we will add it to our list of future items to make.
            </p>
            <p className="faq-par4">
                <h2 className="faq-par-header">When and where do you deliver?</h2>
                Right now, we offer delivery to Williamson county only. We also offer a pick up option from our bakery in Thompson's Station.
                <br />
                <br />
                If you are outside of Williamson county, send us a message <a href="https://hamby-artisan-bakery.myshopify.com/pages/contact">here</a> and we can chat about pick up options!
            </p>
            <p className="faq-par5">
                <h2 className="faq-par-header">Do you offer local pickup for orders?</h2>
                We do! Select 'Pick up' in the checkout options and we will arrange for you to pick up your order from our bakery in Thompson's Station.
            </p>
            <p className="faq-par6">
                <h2 className="faq-par-header">Allergies?</h2>
                While some of our products may be gluten-free or nut-free, we do have nuts, wheat, eggs, and dairy products in our bakery -- so cross-contamination could occur. 
                If you have any allergies or sensitivities to specific ingredients, please use caution and feel free to ask us for information that could help you make the best decision for your needs. 
                Although we make our best efforts, we can’t guarantee cross-contamination has not occurred.
            </p>
            <p className="faq-par7">
                <h2 className="faq-par-header">Subscriptions for baked goods?</h2>
                This is not something that we currently offer but we are working on it! 
                If you are interested in a cookie subscription or some other kind of baked good subscription, please let us know <a href="https://hamby-artisan-bakery.myshopify.com/pages/contact">here</a>.
            </p>
        </div>
    </div>
  </Layout>
)

export const Head = () => <Seo title="Faqs" />

export default Faqs
